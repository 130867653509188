import React from "react"

import Layout from "components/Layout/de"
import Product from "components/Layout/Product/index"
import productImg from "./product.jpg"
import productIcon from "./PaintScanner.svg"
import { Table } from "react-bootstrap"
import { MdDone } from "react-icons/md"

export default function(props) {
  const description = (
    <React.Fragment>
      <p>
        Untersuchen Sie automatisiert mit Hilfe des MoonVision PaintScanners
        lackierte Oberflächen nach Defekten und Qualitätseigenschaften. Durch
        die Nutzung von visuellen Daten, erzeugt durch Kameras, wird Ihnen
        frühzeitig mitgeteilt, Ob ihre Qualitätsmerkmale erfüllt werden und/oder
        ob ein Handlungsbedarf besteht.
      </p>
      <h3>Highlights</h3>
      <ul>
        <li>Automatisierte Qualitätsprüfung von lackierten Oberflächen</li>
        <li>
          Von gleichbleibender Qualität und verkürzter Durchlaufzeit profitieren
        </li>
        <li>Erkennung und Messung mittels Kamera & Software</li>
        <li>Höchste Genauigkeit & Komfort durch Künstliche Intelligenz</li>
        <li>Auswertungen an Folgesysteme oder visuelle Darstellung</li>
      </ul>
    </React.Fragment>
  )

  return (
    <Layout location={props.location}>
      <Product
        id="PaintScanner"
        title="Qualitätsprüfung von Lackierten Flächen in Echtzeit"
        subtitle="Lackoberflächen überprüfen mit der präzisesten Technologie"
        description={description}
        productImg={productImg}
        productIcon={productIcon}
        productFeatures={[
          "Automatisierte Lackprüfung",
          "Gleichbleibend hohe Qualität",
          "Verkürzte Durchlaufzeit",
          "Jetzt Informieren",
        ]}
        technicalTable={
          <Table responsive size="sm">
            <thead>
              <tr>
                <th colSpan="2">Erkennung verschiedener Lackfehler, z.B.:</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Schlechte Deckfähigkeit</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Luft-/ Staubeinschlüsse</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Orangenhaut</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Dellen</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Kratzer</td>
                <td>
                  <MdDone />
                </td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th colSpan="2">Einsatzbereiche:</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Automotive</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Möbel</td>
                <td>
                  <MdDone />
                </td>
              </tr>
            </tbody>
          </Table>
        }
      >
        <React.Fragment>
          <p>
            Der MoonVision PaintScanner wurde speziell für die
            Oberflächenprüfung von Lackierten Bauteilen, zB in der
            Automobilindustrie entwickelt. Dabei werden neueste Technologien wie
            Machine Learning eingesetzt um auch die kleinsten, für Mitarbeiter
            kaum rasch erkennbare Abweichungen festzustellen. Prüfstandards
            können dabei individuell auf Ihren Wunsch hin festgelegt werden. Das
            Ergebnis der automatisierten Qualitätsprüfung kann dazu genutzt zB
            das Bauteil unterschiedlich zu verwerten bzw. Statistiken
            abzuleiten.
          </p>
          <p>
            Durch die eingesetzte Künstliche Intelligenz werden bestehende
            Systeme im Hinblick auf die Prüfgenauigkeit in den Schatten gestellt
            – der Bewertungsprozess wird vollautomatisiert und Sie sparen
            wertvolle Zeit.
          </p>
          <p>
            Dazu werden mehrere Kameras und Belichtungsarten und quellen genutzt
            um die höchstmögliche Genauigkeit zu erzielen. Mit dem System werden
            jene Defektarten erkannt, die auch mit dem menschlichen Auge
            sichtbar sind.
          </p>
          <p>
            Der MoonVision PaintScanner kann an unterschiedlichen
            Produktionsschritten von der Wareneingangskontrolle bis hin zur
            Warenausgangskontrolle eingesetzt werden. Durch das autarke Design
            kann die Software in einen laufenden Fertigungsprozess bzw.
            vorhandene ERP-Systeme integriert werden.
          </p>
          <p>Nach Ablauf der Kontrolle können die Ergebnisse ausserdem:</p>
          <ul>
            <li>
              Visuell dargestellt werden (Auf einem Monitor/Alarme/Ablage von
              jpg Dateien)
            </li>
            <li>Tabellarisch zusammengefasst werden</li>
            <li>
              Per APIs an Managementinformationssysteme weitergegeben werden
            </li>
            <li>Per APIs zur Prozesssteuerung eingesetzt werden</li>
          </ul>
          <p>
            Der MoonVision MetalScanner kann alle bestehenden Kamerasysteme als
            Datenquellen nutzen – die den Genicam Standard erfüllen, daher auch
            bereits bestehende. Gerne beraten wir Sie auch bei der Wahl des
            richtigen Systems. Die Überprüfung kann in Echtzeit über unsere
            Online-Plattform erfolgen. Alternativ dazu können wir das System
            auch vor Ort installieren. In beiden Fällen erhalten Sie Zugriff zur
            MoonVision Toolbox und können damit jederzeit Anpassungen zB zur
            Parameteradaptierung und Personalisierung durchführen.
          </p>
          <h3>Einfach in der Anwendung</h3>
          <p>
            Aufgrund des Software und Hardwaredesigns ist die Optimierung und
            Implementierung des MoonVision PaintScanner mit überschaubaren
            Aufwand vor Ort bei Ihnen realisierbar. Bereits nach der Überprüfung
            einiger Bauteile wissen Sie mit welcher Qualität sie rechnen können
            und ob weitere Vorbereitungsmaßnahmen zur Erhöhung der
            Erkennungsgenauigkeit benötigt werden.
          </p>
        </React.Fragment>
      </Product>
    </Layout>
  )
}
